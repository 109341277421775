// Import style
import style from "./SectionVoluntarios.module.css"

//Import 


function SectionVoluntarios () {

    return(
       
        <section>
            <div className={style.BackgroundImg}></div>
        </section>

    );
};

export default SectionVoluntarios