// Import style
import style from "./SectionSecurity.module.css"


function SectionSecurity () {

    return(
        <section>

            <div className={style.BackgroundImg}></div>

        </section>

    );
};

export default SectionSecurity