//Import Style
import Style from "./SectionAssociado.module.css"

function SectionAssociado () {

  return(
    <section>

        <div className={Style.Background}></div>

    </section>

);
};

export default SectionAssociado